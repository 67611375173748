import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {useMediaQuery} from '@mui/material';
import {
    Box, Stack, Button, useTheme,
} from '@mui/joy';
import DownloadIcon from "@mui/icons-material/FileDownload";

import ActivityList from "components/activity/ActivityList";
import ActivityTable from "components/activity/ActivityTable";
import AddActivityButton from "components/activity/AddActivityButton";
import ActivityFilters from "components/activity/ActivityFilters";
import activityActions from "actions/activityActions";
import {Employee, ActivityCriteria, Account} from "domain";
import SubscriptionNotice from "components/SubscriptionNotice";

const CHUNK_SIZE = 50;

const ActivitiesView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isMore, setMore] = useState(false);
    const activities = useSelector(state => state.activity.list);
    const criteria = useSelector(state => state.activity.criteria);
    const isLoading = useSelector(state => state.activity.isLoading);
    const profile = useSelector(({ employees }) => employees.me);
    const myAccount = useSelector(({ account }) => account.me);

    const billingStatus = myAccount?.billingStatus || Account.BILLING_STATUS_INACTIVE;
    const isDisabled = billingStatus === Account.BILLING_STATUS_DEACTIVATED;

    const handleOpen = (activity) => {
        navigate(`/activity/${activity.id}`);
    }

    const fetchActivities = (newCriteria) => {
        if (newCriteria.valid) {
            dispatch(activityActions.listByWhereAndWhen(newCriteria, CHUNK_SIZE)).then(handleCheckForMore);
        }
    }

    const handleChangeFilters = ({ startDate, endDate, locations, employees }) => {
        const newCriteria = new ActivityCriteria({
            startDate,
            endDate,
            locations,
            employees,
            sortDir: criteria.sortDir,
            sortField: criteria.sortField,
            saveToStore: true
        })
        fetchActivities(newCriteria)
    }

    const handleChangeSort = ({ sortDir, sortField }) => {
        const newCriteria = new ActivityCriteria({
            startDate: criteria.startDate,
            endDate: criteria.endDate,
            locations: criteria.locations,
            employees: criteria.employees,
            sortDir: sortDir,
            sortField: sortField,
            saveToStore: true
        })
        fetchActivities(newCriteria)
    }

    const downloadCSV = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleExportCSV = () => {
        downloadCSV(activities.toCSV(), 'activities.csv');
    };

    const handleCheckForMore = (results) => {
        setMore((results.value?.length ?? 0) >= CHUNK_SIZE);
    }

    const handleMore = () => {
        const exclude = [...activities.map(a => a.id)];
        dispatch(activityActions.listByWhereAndWhen(criteria, CHUNK_SIZE, exclude, true)).then(handleCheckForMore);
    }

    useEffect(() => {
        if (profile) {
            if (profile.type !== Employee.TYPE_ADMIN) {
                // Only need to fire this for employee view, the admin view will be updated via `filters`/`setFilters`
                // component calling `handleFilter`
                dispatch(activityActions.listByEmployee(profile));
            }
        }
    }, [dispatch, profile]);

    if (isDisabled) {
        return <SubscriptionNotice />
    }

    return (
        <Stack>
            {profile?.type === Employee.TYPE_ADMIN && (
                <ActivityFilters
                    currentStartDate={criteria.startDate}
                    currentEndDate={criteria.endDate}
                    currentLocations={criteria.locations}
                    currentEmployees={criteria.employees}
                    onChange={handleChangeFilters}
                />
            )}
            <Box my={2} display="flex" gap={2} justifyContent="end" alignItems="flex-end">
                {profile?.type === Employee.TYPE_ADMIN && (
                    <Button startDecorator={<DownloadIcon />} variant="outlined" onClick={handleExportCSV} disabled={isLoading}>Export CSV</Button>
                )}
                <AddActivityButton />
            </Box>
            {isXsScreen
                ? (
                    <ActivityList
                        activities={activities}
                        onSelect={handleOpen}
                        loading={isLoading}
                        onMore={handleMore}
                        isMore={isMore}
                    />
                ) : (
                    <ActivityTable
                        activities={activities}
                        loading={isLoading}
                        onMore={handleMore}
                        isMore={isMore}
                        sortDir={criteria.sortDir}
                        sortField={criteria.sortField}
                        handleChangeSort={handleChangeSort}
                    />
                )
            }
        </Stack>
    );
}

export default ActivitiesView;
