import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {
    Box, Button, Stack
} from '@mui/joy';

import EventTable from "components/event/EventTable";
import AddIcon from "@mui/icons-material/Add";
import OpenActiveButton from "components/event/OpenActiveButton";
import DispatchIcon from "@mui/icons-material/RocketLaunch";

import eventActions from "actions/eventActions";

const EventsView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const events = useSelector(state => state.event.list);
    const isLoading = useSelector(state => state.event.isLoading);
    const activeEvent = useSelector(({ event }) => event.active);

    useEffect(() => {
        dispatch(eventActions.list())
    }, [])

    const handleNew = () => {
        navigate('/location');
    };

    const handleOpenActive = () => {
        navigate(`/event/${activeEvent.id}`);
    };

    return (
        <Stack>
            <Box mb={2} mt={0} display="flex" gap={2} justifyContent="end" alignItems="flex-end">
                {activeEvent ? (
                    <OpenActiveButton
                        size="sm"
                        activeEvent={activeEvent}
                        onClick={handleOpenActive}
                    >
                        <DispatchIcon/>
                        Launch Dispatch Board
                    </OpenActiveButton>
                ) : (
                    <Button onClick={handleNew} disabled={!!activeEvent} variant="soft">
                        <AddIcon/>
                        Add
                    </Button>
                )}
            </Box>
            <EventTable events={events} loading={isLoading} />
        </Stack>
    );
}

EventsView.propTypes = {};

export default EventsView;
