import {Account} from "domain";

const initState = {
    me: null,
    lov: {},
    isLoaded: false,
};

export default function employeeReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_ACCOUNT_PENDING':
        case 'LOGOUT_USER_PENDING':
        case 'LOAD_ACCOUNT_REJECTED': {
            return { ...initState };
        }

        case 'LOAD_ACCOUNT_PENDING': {
            return { ...state, isLoaded: false };
        }

        case 'LOAD_ACCOUNT_FULFILLED': {
            const { account, lov } = payload;

            return { ...state, me: new Account(account), lov, isLoaded: true };
        }

        case 'UPDATE_SUBSCRIPTION_FULFILLED': {
            return { ...state, me: new Account(payload) };
        }

        default: {
            return state;
        }
    }
}
