import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box, Stack
} from '@mui/joy';

import EmployeeTable from "components/employee/EmployeeTable";
import AddEmployeeButton from "components/employee/AddEmployeeButton";
import employeeActions from "actions/employeeActions";

const EmployeesView = () => {
    const dispatch = useDispatch();
    const employees = useSelector(state => state.employees.list);

    useEffect(() => {
        dispatch(employeeActions.list())
    }, [])

    return (
        <Stack>
            <Box mb={2} mt={0} display="flex" gap={2} justifyContent="end" alignItems="flex-end">
                <AddEmployeeButton />
            </Box>
            <EmployeeTable employees={employees} />
        </Stack>
    );
}

EmployeesView.propTypes = {};

export default EmployeesView;
