import {LocationArray, Location} from "domain";

const initState = {
    list: new LocationArray(),
    current: null,
    isLoading: false,
    isLoaded: false,
};

export default function locationReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_LOCATION_PENDING': {
            return { ...initState };
        }

        case 'LIST_LOCATION_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_LOCATION_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_LOCATION_FULFILLED': {
            return { ...state, list: new LocationArray(payload), isLoaded: true, isLoading: false };
        }

        case 'SAVE_LOCATION_FULFILLED': {
            const current = new Location(payload);
            const list = new LocationArray(current.active
                ? state.list.addUpdate(payload)
                : state.list.remove(payload)
            );
            return {...state, list, current };
        }

        case 'SET_LOCATION_FULFILLED':
        case 'GET_LOCATION_FULFILLED': {
            return { ...state, current: new Location(payload) };
        }

        default: {
            return state;
        }
    }
}
