import { ActivityPicture, ActivityPictureArray } from "domain";

const initState = {
    list: new ActivityPictureArray(),
    current: null,
    isLoaded: false,
};

export default function activityPictureReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_ACTIVITY_PICTURE_PENDING': {
            return { ...initState };
        }

        case 'LIST_ACTIVITY_PICTURE_PENDING':
        case 'GET_ACTIVITY_PICTURE_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_ACTIVITY_PICTURE_REJECTED':
        case 'GET_ACTIVITY_PICTURE_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_ACTIVITY_PICTURE_FULFILLED': {
            return { ...state, list: new ActivityPictureArray(payload), isLoaded: true };
        }

        case 'GET_ACTIVITY_PICTURE_FULFILLED':
        case 'SET_ACTIVITY_PICTURE_FULFILLED':
        case 'SAVE_ACTIVITY_PICTURE_FULFILLED': {
            const current = new ActivityPicture(payload);
            const list = new ActivityPictureArray(state.list.addUpdate(payload));
            return { ...state, list, current };
        }

        default: {
            return state;
        }
    }
}
