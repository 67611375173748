import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ActivityDetails from "components/activity/ActivityDetails";
import ActivityDetailsSkeleton from "components/activity/ActivityDetailsSkeleton"; // Skeleton component for loading state
import { Alert, Typography } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import activityActions from 'actions/activityActions';
import activityPictureActions from 'actions/activityPictureActions';

const ActivityView = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const activity = useSelector(state => state.activity.current);
    const pictures = useSelector(state => state.activityPicture.list);
    const listActivity = useSelector(state => state.activity.list.get(activityId));
    const [isLoading, setIsLoading] = useState(!activity);

    useEffect(() => {
        if (!activity || activity.id !== activityId) {
            if (listActivity) {
                dispatch(activityActions.set(listActivity)).then(() => {
                    setIsLoading(false);
                });
            } else {
                dispatch(activityActions.get(activityId)).then(() => {
                    setIsLoading(false);
                });
            }
        }
    }, [activityId, listActivity, dispatch, activity]);


    useEffect(() => {
        if (activity && activity.id === activityId) {
            dispatch(activityPictureActions.listByActivity(activity));
        }

        return () => {
            dispatch(activityPictureActions.clear());
        }
    }, [activity]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.set(null));
        }
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(activityPictureActions.clear());
        }
    }, []);

    if (isLoading) {
        return <ActivityDetailsSkeleton />;
    }

    if (!activity) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="warning" />}
                variant="soft"
                color="warning"
            >
                <div>
                    <Typography level="h3" color="warning">
                        Activity Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="warning">
                        Sorry, this is an unknown activity.
                    </Typography>
                </div>
            </Alert>
        );
    }

    return (
        <ActivityDetails activity={activity} pictures={pictures} />
    );
}

export default ActivityView;
