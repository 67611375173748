import {EmployeeArray, Employee} from "domain";

const initState = {
    list: new EmployeeArray(),
    current: null,
    me: null,
    isLoading: false,
    isLoaded: false,
};

export default function employeeReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_EMPLOYEE_PENDING': {
            return { ...initState };
        }

        case 'RESTORE_EMPLOYEE_FULFILLED': {
            return { ...state, me: new Employee(payload) };
        }

        case 'LIST_EMPLOYEE_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_EMPLOYEE_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_EMPLOYEE_FULFILLED': {
            return { ...state, list: new EmployeeArray(payload), isLoaded: true, isLoading: false };
        }

        case 'ADD_EMPLOYEE_FULFILLED':
        case 'SAVE_EMPLOYEE_FULFILLED': {
            const current = new Employee(payload);
            const list = new EmployeeArray(current.active
                ? state.list.addUpdate(payload)
                : state.list.remove(payload)
            );
            return {...state, list, current };
        }

        case 'SET_EMPLOYEE_FULFILLED':
        case 'GET_EMPLOYEE_FULFILLED': {
            return { ...state, current: new Employee(payload) };
        }

        default: {
            return state;
        }
    }
}
