import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import account from "./accountReducer";
import activityPicture from "./activityPictureReducer";
import activity from "./activityReducer";
import app from './appReducer';
import auth from "./authReducer";
import event from "./eventReducer";
import eventLocation from "./eventLocationReducer";
import employees from './employeeReducer';
import locations from './locationReducer';

const createAppStore = (initialState = {}) => {
    const reducer =  combineReducers({
        account,
        activityPicture,
        activity,
        app,
        auth,
        event,
        eventLocation,
        employees,
        locations,
    });

    const middleware = applyMiddleware(promise, thunk, createLogger());

    return createStore(reducer, initialState, middleware);
};

export default createAppStore;
