import {EventArray, Event} from "domain";

const initState = {
    list: new EventArray(),
    current: null,
    active: null,
    isLoading: false,
    isLoaded: false,
};

export default function eventReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_EVENT_PENDING': {
            return { ...initState, active: state.active };
        }

        case 'LIST_EVENT_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_EVENT_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_EVENT_FULFILLED': {
            return { ...state, list: new EventArray(payload), isLoaded: true, isLoading: false };
        }

        case 'SAVE_EVENT_FULFILLED': {
            const current = new Event(payload);
            const list = new EventArray(current.active
                ? state.list.addUpdate(payload)
                : state.list.remove(payload)
            );

            if (current.end == null) {
                return {...state, list, current, active: current };
            }

            if (current.id === state.active.id && current.end != null) {
                return {...state, list, current, active: null };
            }

            return {...state, list, current };
        }

        case 'SET_EVENT_FULFILLED':
        case 'GET_EVENT_FULFILLED': {
            const current = new Event(payload);

            if (current.end == null) {
                return {...state, current, active: current };
            }

            return { ...state, current };
        }

        case 'SET_ACTIVE_EVENT_FULFILLED':
        case 'GET_ACTIVE_EVENT_FULFILLED': {
            if (payload) {
                return { ...state, active: new Event(payload) };
            } else {
                return { ...state, active: null };
            }
        }

        default: {
            return state;
        }
    }
}
