import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
    "colorSchemes": {
        "dark": {
            "palette": {
                "primary": {
                    "50": "#fffbeb",
                    "100": "#fef3c7",
                    "200": "#fde68a",
                    "300": "#fcd34d",
                    "400": "#fbbf24",
                    "500": "#f59e0b",
                    "600": "#d97706",
                    "700": "#b45309",
                    "800": "#92400e",
                    "900": "#78350f"
                },
                "danger": {
                    "50": "#fef2f2",
                    "100": "#fee2e2",
                    "200": "#fecaca",
                    "300": "#fca5a5",
                    "400": "#f87171",
                    "500": "#ef4444",
                    "600": "#dc2626",
                    "700": "#b91c1c",
                    "800": "#991b1b",
                    "900": "#7f1d1d"
                },
                "success": {
                    "50": "#ecfdf5",
                    "100": "#d1fae5",
                    "200": "#a7f3d0",
                    "300": "#6ee7b7",
                    "400": "#34d399",
                    "500": "#10b981",
                    "600": "#059669",
                    "700": "#047857",
                    "800": "#065f46",
                    "900": "#064e3b"
                }
            }
        },
        "light": {
            "palette": {
                "primary": {
                    "50": "#fffbeb",
                    "100": "#fef3c7",
                    "200": "#fde68a",
                    "300": "#fcd34d",
                    "400": "#fbbf24",
                    "500": "#f59e0b",
                    "600": "#d97706",
                    "700": "#b45309",
                    "800": "#92400e",
                    "900": "#78350f"
                },
                "danger": {
                    "50": "#fef2f2",
                    "100": "#fee2e2",
                    "200": "#fecaca",
                    "300": "#fca5a5",
                    "400": "#f87171",
                    "500": "#ef4444",
                    "600": "#dc2626",
                    "700": "#b91c1c",
                    "800": "#991b1b",
                    "900": "#7f1d1d"
                },
                "success": {
                    "50": "#ecfdf5",
                    "100": "#d1fae5",
                    "200": "#a7f3d0",
                    "300": "#6ee7b7",
                    "400": "#34d399",
                    "500": "#10b981",
                    "600": "#059669",
                    "700": "#047857",
                    "800": "#065f46",
                    "900": "#064e3b"
                }
            }
        },
    },
    components: {
        JoyChip: {
            defaultProps: {
                variant: "soft",
                size: "sm",
            },
            styleOverrides: {
                root: {
                    '--Chip-paddingInline': '24px',
                },
            },
        },
        JoyInput: {
            defaultProps: {
                color: "neutral",
                size: "lg",
                variant: "outlined",
            }
        },
        JoyTextarea: {
            defaultProps: {
                color: "neutral",
                size: "lg",
                variant: "outlined",
            }
        },
        JoySelect: {
            defaultProps: {
                color: "neutral",
                size: "lg",
                variant: "outlined",
            }
        },
        JoySheet: {
            defaultProps: {
                variant: "soft",
            },
            styleOverrides: {
                root: {
                    'borderRadius': 'var(--joy-radius-xs)',
                    'boxShadow': 'var(--joy-shadow-sm)',
                },
            },
        }
    },
})

export default theme;
