import React from 'react';
import AccountSettings from "components/employee/AccountSettings";
import {Box} from "@mui/joy";

const SubscriptionView = () => (
    <>
        <AccountSettings/>
        <Box mt={8} align="center" component="div">
            <img src="img/subscriptions.svg" alt="Subscription Portal" style={{ width: '100%', maxWidth: 580 }} />
        </Box>
    </>
);

SubscriptionView.propTypes = {};

export default SubscriptionView;
