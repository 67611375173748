import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box, Stack, Button,
} from '@mui/joy';

import LocationTable from "components/location/LocationTable";
import AddLocationButton from "components/location/AddLocationButton";
import DispatchIcon from "@mui/icons-material/RocketLaunch";
import eventActions from "actions/eventActions";
import { Message} from "domain";
import {useNavigate} from "react-router-dom";
import appActions from "actions/appActions";
const LocationsView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selected, setSelected] = useState([])
    const locations = useSelector(state => state.locations.list);
    const activeEvent = useSelector(({ event }) => event.active);

    const handleDeploy = () => {
        dispatch(eventActions.add([...selected]))
            .then((response) => {
                const { event } = response.value;
                dispatch(appActions.addNotice(
                    new Message({ title: 'Event Successful', type: Message.TYPE_SUCCESS }))
                );
                navigate(`/event/${event.id}`);
            })
            .catch((error) => {
                dispatch(appActions.addNotice(
                    new Message({ title: 'Event Failed', type: Message.TYPE_ERROR, details: error.details }))
                );
            });
    };

    return (
        <Stack>
            <Box mb={2} mt={0} display="flex" gap={2} justifyContent="end" alignItems="flex-end">
                <Button
                    variant="soft"
                    onClick={handleDeploy}
                    disabled={selected.length === 0 || activeEvent != null}
                >
                    <DispatchIcon />
                    Deploy
                </Button>
                <AddLocationButton />
            </Box>
            <LocationTable locations={locations} selected={selected} onSelect={setSelected} />
        </Stack>
    );
}

LocationsView.propTypes = {};

export default LocationsView;
