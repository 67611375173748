import { Activity, ActivityArray, ActivityCriteria } from "domain";

const initState = {
    list: new ActivityArray(),
    current: null,
    isLoaded: false,
    criteria: new ActivityCriteria()
};

export default function activityReducer(state = initState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case 'CLEAR_ACTIVITY_PENDING': {
            return { ...initState, list: new ActivityArray([]) };
        }

        case 'LIST_ACTIVITY_PENDING':
        case 'GET_ACTIVITY_BY_DEPLOYMENT_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'LIST_ACTIVITY_REJECTED':
        case 'GET_ACTIVITY_BY_DEPLOYMENT_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'LIST_ACTIVITY_FULFILLED': {
            return { 
                ...state,
                list: new ActivityArray(payload),
                isLoaded: true,
                isLoading: false,
                criteria: meta?.criteria?.saveToStore ? meta.criteria : state.criteria
            };
        }

        case 'SET_ALL_ACTIVITY_FULFILLED':
        case 'SAVE_ALL_ACTIVITY_FULFILLED':
        case 'GET_ACTIVITY_BY_DEPLOYMENT_FULFILLED': {
            return { ...state, list: new ActivityArray(payload), isLoaded: true, isLoading: false };
        }

        case 'APPEND_ACTIVITY_FULFILLED':
        case 'ADD_ALL_ACTIVITY_FULFILLED': {
            return { ...state, list: new ActivityArray([...state.list, ...payload]), isLoaded: true, isLoading: false };
        }

        case 'GET_ACTIVITY_FULFILLED':
        case 'SET_ACTIVITY_FULFILLED':
        case 'SAVE_ACTIVITY_FULFILLED': {
            const current = new Activity(payload);
            const list = new ActivityArray([...state.list.addUpdate(payload)]);
            return {...state, list, current };
        }

        default: {
            return state;
        }
    }
}
