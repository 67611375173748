import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { DateTime } from 'luxon';
import { Alert, Box, Typography, Stack, Button } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";
import DispatchIcon from "@mui/icons-material/Send";
import ActivityIcon from "@mui/icons-material/Newspaper";

import CommonUtils from 'utils/CommonUtils';
import EventDetails from "components/event/EventDetails";
import EventDetailsSkeleton from "components/event/EventDetailsSkeleton";
import EventLocationTable from "components/event/EventLocationTable";
import ActivityDispatcher from 'components/activity/ActivityDispatcher';
import EventProgressBar from "components/event/EventProgressBar";
import activityActions from 'actions/activityActions';
import eventActions from 'actions/eventActions';
import eventLocationActions from 'actions/eventLocationActions';
import {ActivityArray, Activity, EventLocationArray, ActivityCriteria} from "domain";

const EventView = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const event = useSelector(state => state.event.current);
    const eventLocations = useSelector(state => state.eventLocation.current);
    const activities = useSelector(state => state.activity.list);
    const employees = useSelector(state => state.employees.list);
    const [selected, setSelected] = useState(new EventLocationArray())
    const [isEventLoaded, setEventLoaded] = useState(!event || event.id !== eventId);
    const [isLocationsLoaded, setLocationsLoaded] = useState(false);
    const [isActivitiesLoaded, setActivitiesLoaded] = useState(false);
    const [isDispatcherOpen, setDispatcherOpen] = useState(false);

    const updateEvent = (updatedEvent) => {
        if (updatedEvent.id === eventId) {
            dispatch(eventActions.set(updatedEvent));
        }
    };

    const updateActivity = (updatedActivity) => {
        dispatch((dispatch, getState) => {
            const currentActivities = getState().activity.list;
            dispatch(activityActions.setAll(currentActivities.addUpdate(updatedActivity)));
        });
    };

    const clearActivity = (updatedActivity) => {
        dispatch((dispatch, getState) => {
            const currentActivities = getState().activity.current;
            const currentEventLocations = getState().eventLocation.current;
            if (currentEventLocations.contains(updatedActivity.get('eventLocation').id)) {
                dispatch(activityActions.setAll(currentActivities.remove(updatedActivity)));
            }
        });
    };

    const handleDispatch = (newActivities) => {
        CommonUtils.dispatcher(
            activityActions.addAll(newActivities),
            dispatch,
            'Employees Dispatched',
            'Failed To Dispatched Employees'
        ).then(() => {
            setSelected(new EventLocationArray())
        });
    };

    const formatDate = (date) => {
        if (!date || !(date instanceof Date)) return '';
        return DateTime.fromJSDate(date).toLocal().toFormat('yyyy-MM-dd\'T\'HH:mm');
    };

    const handleOpenActivity = () => {
        const queryParameters = {
            start: formatDate(event.start),
            end: formatDate(event.end),
            locations: eventLocations.map(dl => dl.location.id).join(',')
        };

        navigate(`/activity?${queryString.stringify(queryParameters)}`);
    }

    useEffect(() => {
        if (!event || event.id !== eventId) {
            setEventLoaded(false);
            setLocationsLoaded(false);
            setActivitiesLoaded(false);

            dispatch(eventActions.get(eventId)).then(() => {
                setEventLoaded(true);
                dispatch(eventActions.watch(eventId, {
                    'update': updateEvent,
                }));
            });

        }

        return () => {
            dispatch(eventActions.unwatch(eventId))
        }
    }, [eventId, dispatch]);

    useEffect(() => {
        if (!isLocationsLoaded && isEventLoaded && event) {
            dispatch(eventLocationActions.getByEvent(event)).then(() => {
                setLocationsLoaded(true);
            });
        }
    }, [event, isEventLoaded]);

    useEffect(() => {
        if (!isActivitiesLoaded && isLocationsLoaded && eventLocations) {
            const locations = [...eventLocations].map(dl => dl.location)
            const startDate = event.start
            const endDate = event.end
            const filters = new ActivityCriteria({startDate, endDate, locations})

            if(filters.valid) {
                dispatch(activityActions.listByWhereAndWhen(filters, 1000)).then(() => {
                    if (endDate == null) {
                        dispatch(activityActions.watchByWhereAndWhen(filters, {
                            'update': updateActivity,
                            'create': updateActivity,
                            'enter': updateActivity,
                            'leave': clearActivity,
                            'delete': clearActivity,
                        }));
                    }
                    setActivitiesLoaded(true);
                })
            }
        }

        return () => {
            dispatch(activityActions.unwatchByWhereAndWhen())
        }
    }, [eventLocations, isLocationsLoaded]);

    useEffect(() => {
        return () => {
            dispatch(eventActions.unwatch(eventId))
            dispatch(activityActions.unwatchByWhereAndWhen())
            dispatch(eventActions.clear())
            dispatch(eventLocationActions.clear())
            dispatch(activityActions.clear())
        }
    }, []);

    if (!isEventLoaded || !isLocationsLoaded || !isActivitiesLoaded) {
        return <EventDetailsSkeleton />;
    }

    if (!event) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="warning" />}
                variant="soft"
                color="warning"
            >
                <Typography level="h3" color="warning">
                    Event Not Found
                </Typography>
                <Typography level="body-md" variant="plain" color="warning">
                    Sorry, this event cannot be found or it does not exist.
                </Typography>
            </Alert>
        );
    }

    const calculateProgress = () => {
        const pending = eventLocations?.filter(dl =>
            !activities?.some(act => act.location.id === dl.location.id)
        ).length ?? 0;

        const inProgress = activities?.filter(act =>
            act.status !== Activity.STATUS_COMPLETE
        ).length ?? 0;

        const completed = activities?.filter(act =>
            act.status === Activity.STATUS_COMPLETE
        ).length ?? 0;

        return { pending, inProgress, completed };
    };

    const { pending, inProgress, completed } = calculateProgress();

    return (
        <>
            <EventDetails event={event}/>
            <EventProgressBar
                pending={pending}
                inProgress={inProgress}
                completed={completed}
                active={event.end == null}
            />
            <Box mt={4}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="end"
                    justifyContent="space-between"
                    pb={2}
                >
                    <Typography level="h5" component="h2">Locations</Typography>
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={handleOpenActivity}
                            variant="outlined"
                            color="primary"
                            startDecorator={<ActivityIcon/>}
                        >
                        Activity Report
                        </Button>
                        <Button
                            onClick={() => setDispatcherOpen(true)}
                            color="primary"
                            variant="soft"
                            disabled={selected.length === 0 || event.end != null}
                        >
                            <DispatchIcon mr={10.5} />
                        &nbsp;Dispatch
                        </Button>
                        <ActivityDispatcher
                            eventLocations={selected}
                            onSubmit={handleDispatch}
                            onClose={() => setDispatcherOpen(false)}
                            open={isDispatcherOpen}
                        />
                    </Stack>
                </Box>
                <EventLocationTable
                    eventLocations={eventLocations}
                    activities={activities ?? new ActivityArray()}
                    employees={employees}
                    selected={selected}
                    onSelect={setSelected}
                />
            </Box>
        </>
    );
}

export default EventView;
