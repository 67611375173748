import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import EmployeeDetails from "components/employee/EmployeeDetails";
import EmployeeDetailsSkeleton from "components/employee/EmployeeDetailsSkeleton";
import { Alert, Typography } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import employeeActions from 'actions/employeeActions';

const EmployeeView = () => {
    const { employeeId } = useParams();
    const dispatch = useDispatch();
    const employee = useSelector(state => state.employees.current);
    const listEmployee = useSelector(state => state.employees.list.get(employeeId));
    const [isLoading, setIsLoading] = useState(!employee);

    useEffect(() => {
        if (!employee || employee.id !== employeeId) {
            if (listEmployee) {
                dispatch(employeeActions.set(listEmployee)).then(() => {
                    setIsLoading(false);
                });
            } else {
                dispatch(employeeActions.get(employeeId)).then(() => {
                    setIsLoading(false);
                });
            }
        }
    }, [employeeId, listEmployee, dispatch, employee]);

    useEffect(() => {
        return () => {
            dispatch(employeeActions.set(null));
        }
    }, [dispatch]);

    if (isLoading) {
        return <EmployeeDetailsSkeleton />;
    }

    if (!employee) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="warning" />}
                variant="soft"
                color="warning"
            >
                <div>
                    <Typography level="h3" color="warning">
                        Employee Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="warning">
                        Sorry, this is an unknown employee.
                    </Typography>
                </div>
            </Alert>
        );
    }

    return (
        <EmployeeDetails employee={employee}/>
    );
}

EmployeeView.propTypes = {};

export default EmployeeView;
