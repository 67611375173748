import {Message} from "domain";
import appActions from "actions/appActions";
import { DateTime } from 'luxon';
import _ from 'lodash';

export default class CommonUtils {
    static EMPTY_FUNCTION = () => {};

    static generateKey = () => (Math.random() * 0xFFFFFF << 0).toString(16)

    static dispatcher = (action, dispatch, successMessage = 'Success', errorMessage = 'Error') => {
        return dispatch(action)
            .then((response) => {
                const successMsg = (typeof successMessage === 'string') ?
                    { title: successMessage, type: Message.TYPE_SUCCESS } :
                    { type: Message.TYPE_SUCCESS, ...successMessage };
                dispatch(appActions.addNotice(new Message(successMsg)));
                return response;
            })
            .catch((error) => {
                const successMsg = (typeof errorMessage === 'string') ?
                    { title: errorMessage, details: error.details, type: Message.TYPE_ERROR } :
                    { type: Message.TYPE_ERROR, details: error.details, ...errorMessage };
                dispatch(appActions.addNotice(new Message(successMsg)));
                return error;
            });
    };

    static deepEqual = (object1, object2) => _.isEqual(object1, object2);

    static formatDate = (date) => {
        if (!date) return '';
        let dateTime;
        if (typeof date === 'string') {
            dateTime = DateTime.fromISO(date);
        } else if (date.iso) {
            dateTime = DateTime.fromISO(date.iso);
        } else if (date instanceof Date) {
            dateTime = DateTime.fromJSDate(date);
        }
        return dateTime ? dateTime.toLocaleString(DateTime.DATE_MED) : '';
    };

    static formatDateRange = (start, end) => {
        if (end && !CommonUtils.isSameDay(start, end)) {
            return `${CommonUtils.formatDate(start)} - ${CommonUtils.formatDate(end)}`;
        }
        return CommonUtils.formatDate(start);
    };

    static formatDateTime = (date) => {
        if (!date) return '';
        let dateTime;
        if (typeof date === 'string') {
            dateTime = DateTime.fromISO(date);
        } else if (date.iso) {
            dateTime = DateTime.fromISO(date.iso);
        } else if (date instanceof Date) {
            dateTime = DateTime.fromJSDate(date);
        }
        return dateTime ? dateTime.toLocaleString(DateTime.DATETIME_MED) : '';
    };

    static toInputDate = (date) => {
        return date ? DateTime.fromJSDate(date).toFormat("yyyy-MM-dd'T'HH:mm") : '';
    };

    static toJSDate = (value) => {
        return value ? DateTime.fromISO(value).toJSDate() : null;
    }

    static fromNow = (date, def = 'N/A') => {
        if (!date) return def;

        let dateStr;
        if (date.iso) {
            dateStr = date.iso;
        } else if (typeof date === 'string' || date instanceof DateTime) {
            dateStr = date;
        } else {
            return def;
        }

        const dateTime = DateTime.fromISO(dateStr);
        const now = DateTime.now();

        if (now.diff(dateTime, 'minutes').minutes < 1) {
            return "Now";
        }

        return dateTime.toRelative() ?? def;
    };

    static isSameDay = (date1, date2) => {
        if (date1 == null || date2 == null) {
            return false;
        }

        const parseDate = (date) => {
            if (date.iso) {
                return DateTime.fromISO(date.iso);
            } else if (date instanceof Date) {
                return DateTime.fromJSDate(date);
            } else if (date instanceof DateTime) {
                return date;
            } else {
                return DateTime.fromISO(date);
            }
        };

        const dt1 = parseDate(date1);
        const dt2 = parseDate(date2);

        return dt1.hasSame(dt2, 'day');
    };

    static escapeCSVField = (field) => {
        if (field.includes(',') || field.includes('\n')) {
            return `"${field.replace(/"/g, '""').replace(/\n/g, '\r\n')}"`;
        }
        return field;
    };

    static openDirections = (address) => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`, '_blank');
    };
}
