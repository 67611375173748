import React from 'react';
import {useSelector} from "react-redux";

import AdminDashboard from "components/AdminDashboard";
import UserDashboard from "components/UserDashboard";
import {Employee} from "domain";

function DashboardView() {
    const profile = useSelector(({ employees }) => employees.me);

    if (!profile) {
        return null;
    }

    return profile.type === Employee.TYPE_ADMIN ? (
        <AdminDashboard />
    ) : (
        <UserDashboard />
    );
}

export default DashboardView;
