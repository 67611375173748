import {EventLocationArray} from "domain";

const initState = {
    current: new EventLocationArray(),
    isLoading: false,
    isLoaded: false,
};

export default function eventLocationReducer(state = initState, action) {
    const { type, payload } = action;

    switch (type) {
        case 'CLEAR_EVENT_LOCATION_PENDING': {
            return { ...initState };
        }

        case 'GET_EVENT_LOCATION_BY_EVENT_PENDING': {
            return { ...state, isLoading: true };
        }

        case 'GET_EVENT_LOCATION_BY_EVENT_REJECTED': {
            return { ...state, isLoading: false };
        }

        case 'SET_ALL_EVENT_LOCATION_FULFILLED':
        case 'GET_EVENT_LOCATION_BY_EVENT_FULFILLED': {
            return { ...state, current: new EventLocationArray(payload), isLoaded: true, isLoading: false };
        }

        default: {
            return state;
        }
    }
}
