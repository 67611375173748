import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LocationDetails from "components/location/LocationDetails";
import LocationDetailsSkeleton from "components/location/LocationDetailsSkeleton";
import {Alert, Typography} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import locationActions from 'actions/locationActions';

const LocationView = () => {
    const { locationId } = useParams();
    const dispatch = useDispatch();
    const location = useSelector(state => state.locations.current);
    const listLocation = useSelector(state => state.locations.list.get(locationId));
    const [isLoading, setIsLoading] = useState(!location);

    useEffect(() => {
        if (!location || location.id !== locationId) {
            if (listLocation) {
                dispatch(locationActions.set(listLocation)).then(() => {
                    setIsLoading(false);
                });
            } else {
                dispatch(locationActions.get(locationId)).then(() => {
                    setIsLoading(false);
                });
            }
        }
    }, [locationId, listLocation]);

    useEffect(() => {
        return () => {
            dispatch(locationActions.set(null));
        }
    }, []);

    if (isLoading) {
        return <LocationDetailsSkeleton />;
    }

    if (!location) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="warning" />}
                variant="soft"
                color="warning"
            >
                <div>
                    <Typography level="h3" color="warning">
                        Location Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="warning">
                        Sorry, this is an unknown location.
                    </Typography>
                </div>
            </Alert>
        );
    }

    return (
        <LocationDetails location={location}/>
    );
}

LocationView.propTypes = {};

export default LocationView;
